<template>
    <div class="w-full mb-4 px-4">
        <div class="flex">
            <a @click.prevent="clear" class="text-brand hover:bg-brand-light mb-2 mr-4">
                Сбросить все фильтры
            </a>
        </div>

        <FilterGameTypes ref="gameTypes" @input="gameTypes">
              </FilterGameTypes>
    </div>
</template>

<script>
import axios from 'axios'
import FilterGameTypes from '@/components/FilterGameTypes'
export default {
  name: 'GameTemplateFilter',
  components: {
    FilterGameTypes,
  },
  data () {
    return {
      filter: {
        game_type_id: this.$route.query['game_type_id'] || '',
        game_topic_id: this.$route.query['game_topic_id'] || '',
      },
    }
  },
  created () {
  },
  watch: {
    filter: {
      handler (val) {
        this.addFilters(val)
      },
      deep: true,
    },

  },
  methods: {
    addFilters (filter) {
      let filterData = {}
      for (let key in filter) {
        if (filter[key] === '') {
          continue
        } else filterData[key] = filter[key]
      }
      this.$router.push({ query: filterData })
    },
    gameTypes (gameTypeId) {
      if (gameTypeId) {
        this.filter.game_type_id = gameTypeId.query
      }
    },
    clear () {
      this.$refs.gameTypes.clear()
    },
  },

}
</script>

<style scoped>
    @tailwind utilities;
</style>
