<template>
    <div>
        <div class="z-10 content rounded">
            <div class="text-center flex flex-col items-center">
            <label class="font-semibold text-brand text-lg" for="name">Введите название шаблона</label>
            <input v-model="name" type="text" id="name" name="name" class="border border-brand mt-4 w-64 h-8 rounded">
            </div>
            <div class="flex justify-center mt-6">
              <button @click="send" class="px-3 py-2 bg-brand text-white mx-4 rounded">
                Подтвердить
              </button>
              <button @click="$emit('close')" class="px-3 py-2 bg-brand text-white mx-4 rounded">
                Отменить
              </button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'copy-modal',
  props: ['id'],
  data () {
    return {
      name: '',
    }
  },
  methods: {
    send () {
      axios.post(`api/templates/${this.id}/copy`, {
        name: this.name,
      }).then((data) => {
        this.$emit('updateData')
      })
    },
  },
}
</script>
<style scoped>
.content {
    background-color: white;
    position: fixed;
    top: 30%;
    left: 35%;
    width: 360px;
    border: 2px solid gray;
    padding-top: 40px;
    padding-bottom: 40px;
}
</style>
