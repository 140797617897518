<template>
    <div v-if="Object.values(gameTypes).length > 0 && defaultType" class="container mx-auto flex flex-col px-8 mx-4">
        <game-template-filter class="z-10"></game-template-filter>
        <div class="container px-4 mx-auto flex justify-between">
            <smart-filter :main-url="'/api/templates?sort=-created_at&'" :route="$route">
                <div class="w-full" slot-scope="{link, values}">
                    <pagination :base-url="link">
                        <div class="w-full" slot-scope="{data: templates, processing, switchPage, fetchData}">
                            <button @click.prevent="create(fetchData)"
                                    class="flex ml-auto mb-1 text-white no-underline px-4 py-2 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                                Добавить шаблон
                            </button>
                            <div class="w-full" v-if="!processing">
                                <item-count class="justify-start" :title="'шаблонов'"></item-count>
                                <div class="rounded border-grey shadow-md text-left w-full overflow-x-auto">
                                    <table class="w-full p-4">
                                        <thead>
                                        <tr>
                                            <th>
                                                <a href="" class="text-grey-darkest no-underline hover:text-grey mx-auto my-auto"
                                                   title="Выбрать всё на этой странице"
                                                   @click.prevent="selectAll(templates)">
                                                    <font-awesome-icon v-if="checked" class="w-6"
                                                                       :icon="['fas', 'angle-up']"></font-awesome-icon>
                                                    <font-awesome-icon v-else class="w-6"
                                                                       :icon="['fas', 'angle-down']"></font-awesome-icon>
                                                </a>
                                            </th>
                                            <th>№</th>
                                            <th class="mr-auto">Название шаблона</th>
                                            <th class="mr-auto">Система</th>
                                            <th class="mr-auto">Описание игры</th>
                                            <th class="mr-auto">Туры</th>
                                            <th> </th>
                                            <th v-if="isParty || isWom || isCorp || isLive || isFamily"> </th>
                                            <th></th>
                                            <th class="border-r-0 ">
                                                <a href="" class="text-grey-darkest no-underline hover:text-grey"
                                                   title="Удалить выбранные"
                                                   @click.prevent="remove(selected, fetchData)">
                                                    <trash></trash>
                                                </a>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <game-template v-for="(template, key) in templates" :value="template" :key="key"
                                                       :template="template"
                                                       :is-party="isParty"
                                                       :is-wom="isWom" :is-corp="isCorp"
                                                       :is-family="isFamily"
                                                       :is-live="isLive"
                                                       :checked="selected.indexOf(template.id) !== -1"
                                                       @remove="remove([template.id], fetchData)"
                                                       @select="selectTemplate(template.id)"
                                                       @updated="fetchData"

                                        ></game-template>
                                        </tbody>
                                    </table>
                                </div>
                                <pagination-links @page="switchPage"></pagination-links>
                            </div>
                            <portal to="create-new-template">

                            </portal>
                        </div>
                    </pagination>
                </div>
            </smart-filter>
        </div>

    </div>
</template>

<script>

import Pagination from '../../components/pagination.vue'
import PaginationLinks from '../../components/pagination-links.vue'
import Trash from '../../icons/trash'
import map from 'lodash/map'
import axios from 'axios'
import swal from 'sweetalert2'
import ItemCount from '../../components/itemCount'
import GameTemplate from './template'
import SmartFilter from '../../components/questions/filter.vue'
import GameTemplateFilter from './filter-panel'

export default {
  components: {
    GameTemplateFilter,
    GameTemplate,
    ItemCount,
    Trash,
    PaginationLinks,
    Pagination,
    SmartFilter,
  },
  watch: {
    '$route' (to, from) {
      this.clearSelected()
    },
  },
  data () {
    return {
      selected: [],
      checked: false,
      gameTypes: {},
    }
  },
  created () {
    this.getGameTypes()
  },
  methods: {
    create (fetchData) {
      axios.post(`/api/templates`).then(({ data }) => {
        fetchData()
        this.$router.push({ name: 'template', params: { id: data.data.id } })
      })
    },
    selectAll (items) {
      let ids = map(items, 'id')
      if (this.selected.length === ids.length) {
        this.clearSelected()
      } else {
        this.selected = ids
        this.checked = true
      }
    },
    selectTemplate (id) {
      if (this.selected.indexOf(id) !== -1) {
        this.dropSelected(this.selected, id)
      } else {
        this.selected.push(id)
      }
    },
    clearSelected () {
      this.selected = []
      this.checked = false
    },
    dropSelected (array, id) {
      let index = array.indexOf(id)
      if (index !== -1) array.splice(index, 1)
    },
    remove (ids, fetchData) {
      if (ids.length === 0) {
        swal('Выберите шаблоны!', 'Необходимо выбрать элементы для удаления', 'error')
        return
      }
      let message
      let link
      ids = ids.join('&ids[]=')
      link = `/api/templates?ids[]=${ids}`
      message = 'Шаблоны будут удален, это действие невозможно отменить!'
      this.confirmDelete(message).then((result) => {
        axios.delete(link).then(() => {
          swal('Удалено!', 'Шаблоны удалены.', 'success')
          fetchData()
          this.clearSelected()
        })
      })
    },

    confirmDelete (message) {
      return new Promise(function (resolve) {
        swal({
          title: 'Вы уверены?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да удалить!',
          cancelButtonText: 'Отмена',
        }).then((result) => {
          if (result.value) {
            resolve(result)
          }
        })
      })
    },
    getGameTypes () {
      axios.get('/partners/game-types')
        .then((data) => {
          console.log(data.data.data);

          this.gameTypes = data.data.data
        })
    },
  },
  computed: {
    defaultType () {

      return true;
      if (Object.values(this.gameTypes).length > 0) {
        return Object.values(this.gameTypes).filter((item) => {
          return item.name === 'MozgoParty'
        })[0]
      }
      return ''
    },
    isParty () {
      return true;
      if (this.$route.query.game_type_id == this.defaultType.id) { return true }
      return Object.keys(this.defaultType.subType.data).includes(this.$route.query.game_type_id.toString())
    },
    isWom () {
      return false;
      if (Object.values(this.gameTypes).length > 0) {
        let womId = ''
        Object.values(this.gameTypes).forEach((type, key) => {
          if (['World of Mozgo', 'Большая игра'].includes(type.name)) {
            womId = type.id
          }
          if (Object.keys(type.subType.data).length > 0) {
            Object.values(type.subType.data).forEach((subType) => {
              if (['World of Mozgo', 'Большая игра'].includes(subType.name)) {
                womId = subType.id
              }
            })
          }
        })
        return this.$route.query.game_type_id.toString() === womId.toString()
      }
      return false
    },
    isCorp () {
      return false;
      if (Object.values(this.gameTypes).length > 0) {
        let womId = ''
        Object.values(this.gameTypes).forEach((type, key) => {
          if (['Корпоративная лига'].includes(type.name)) {
            womId = type.id
          }
          if (Object.keys(type.subType.data).length > 0) {
            Object.values(type.subType.data).forEach((subType) => {
              if (['Корпоративная лига'].includes(subType.name)) {
                womId = subType.id
              }
            })
          }
        })
        return this.$route.query.game_type_id.toString() === womId.toString()
      }
      return false
    },
    isFamily () {
      return false;
      if (Object.values(this.gameTypes).length > 0) {
        let womId = ''
        Object.values(this.gameTypes).forEach((type, key) => {
          if (['Дополнительные продукты'].includes(type.name)) {
            if (Object.keys(type.subType.data).length > 0) {
              Object.values(type.subType.data).forEach((subType) => {
                if (['Семейная'].includes(subType.name)) {
                  womId = subType.id
                }
              })
            }
          }
        })
        return this.$route.query.game_type_id.toString() === womId.toString()
      }
      return false
    },
    isLive () {
      return false;
      let result = false
      if (Object.values(this.gameTypes).length > 0) {
        Object.values(this.gameTypes).forEach((type, key) => {
          if (['Мозгобойня Live'].includes(type.name)) {
            if (this.$route.query.game_type_id.toString() === type.id.toString()) result = true
            if (Object.keys(type.subType.data).length > 0) {
              Object.values(type.subType.data).forEach((subType) => {
                if (this.$route.query.game_type_id.toString() === subType.id.toString()) result = true
              })
            }
          }
        })
      }
      return result
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>
