<template>
  <div class="container mx-auto flex flex-col px-8 mx-4">
    <game-filter></game-filter>
    <div class="container px-4 mx-auto flex justify-between">
      <smart-filter :main-url="'/api/games?sort=-created_at&'" :route="$route">
        <div class="w-full" slot-scope="{link, values}">
          <pagination :base-url="link">
            <div class="w-full" slot-scope="{data: games, processing, switchPage, fetchData}">
              <div class="w-full" v-if="!processing">
                <item-count class="justify-start" :title="'игр'"></item-count>
                <div class="rounded border-grey shadow-md text-left w-full overflow-x-auto">
                  <table class="w-full p-4">
                    <thead>
                    <tr>
                      <th>
                        <a href=""
                           class="text-grey-darkest no-underline hover:text-grey mx-auto my-auto"
                           title="Выбрать всё на этой странице"
                           @click.prevent="selectAll(games)">
                          <font-awesome-icon v-if="checked" class="w-6"
                                             :icon="['fas', 'angle-up']"></font-awesome-icon>
                          <font-awesome-icon v-else class="w-6"
                                             :icon="['fas', 'angle-down']"></font-awesome-icon>
                        </a>
                      </th>
                      <th>№</th>
                      <th class="mr-auto">Система</th>
                      <th class="mr-auto">Шаблон игры</th>
                      <th class="mr-auto">Название</th>
                      <th class="mr-auto">Город</th>
                      <th class="mr-auto">Дата игры</th>
                      <th class="mr-auto">Опубликовано</th>
                      <th v-if="$route.query.state === 'failed'"></th>
                      <th></th>
                      <th></th>

                      <th>
                        <a href="" class="text-grey-darkest no-underline hover:text-grey"
                           title="Удалить выбранные"
                           @click.prevent="remove(selected, fetchData)">
                          <trash></trash>
                        </a>
                      </th>
                      <th class="border-r-0 ">
                        <a href="" @click.prevent="" class="text-grey-darkest no-underline hover:text-grey">
                          <font-awesome-icon class="w-6 mx-auto my-auto text-black text-base"
                                             :icon="['fab', 'telegram-plane']"></font-awesome-icon>
                        </a>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <game v-for="(game, key) in games" :value="game" :key="key"
                          :game="game"
                          :checked="selected.indexOf(game.id) !== -1"
                          @remove="remove([game.id], fetchData)"
                          @select="selectTemplate(game.id)"
                          @updated="fetchData"
                    ></game>
                    </tbody>
                  </table>
                </div>
                <pagination-links @page="switchPage"></pagination-links>
              </div>
              <spinner v-else></spinner>
            </div>
          </pagination>
        </div>
      </smart-filter>
    </div>
  </div>
</template>

<script>

import Pagination from '../../components/pagination.vue'
import PaginationLinks from '../../components/pagination-links.vue'
import Trash from '../../icons/trash'
import map from 'lodash/map'
import axios from 'axios'
import swal from 'sweetalert2'
import ItemCount from '../../components/itemCount'
import Game from './game'
import Spinner from '../../components/spinner'
import SmartFilter from '../../components/questions/filter.vue'
import GameFilter from './filter-panel'

export default {
  components: {
    GameFilter,
    Spinner,
    Game,
    ItemCount,
    Trash,
    PaginationLinks,
    Pagination,
    SmartFilter,
  },
  watch: {
    '$route'(to, from) {
      this.clearSelected()
    },
  },
  data() {
    return {
      selected: [],
      checked: false,
    }
  },
  methods: {
    selectAll(items) {
      let ids = map(items, 'id')
      if (this.selected.length === ids.length) {
        this.clearSelected()
      } else {
        this.selected = ids
        this.checked = true
      }
    },
    selectTemplate(id) {
      console.log('1')
      console.log(this.games)
      if (this.selected.indexOf(id) !== -1) {
        this.dropSelected(this.selected, id)
      } else {
        this.selected.push(id)
      }
    },
    clearSelected() {
      this.selected = []
      this.checked = false
    },
    dropSelected(array, id) {
      let index = array.indexOf(id)
      if (index !== -1) array.splice(index, 1)
    },
    remove(ids, fetchData) {
      if (ids.length === 0) {
        swal('Выберите записи!', 'Необходимо выбрать элементы для удаления', 'error')
        return
      }
      let message
      let link
      ids = ids.join('&ids[]=')
      link = `/api/games?ids[]=${ids}`
      message = 'Игры будут удален, это действие невозможно отменить!'
      this.confirmDelete(message).then((result) => {
        axios.delete(link).then(() => {
          swal('Удалено!', 'Игры удалены.', 'success')
          fetchData()
          this.clearSelected()
        })
      })
    },

    confirmDelete(message) {
      return new Promise(function (resolve) {
        swal({
          title: 'Вы уверены?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да удалить!',
          cancelButtonText: 'Отмена',
        }).then((result) => {
          if (result.value) {
            resolve(result)
          }
        })
      })
    },
  },
}
</script>

<style scoped>
@tailwind utilities;

th {
  @apply text-left
  p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
}
</style>
